import 'babel-polyfill'

import "@fontsource/montserrat/400.css"
import "@fontsource/montserrat/700.css"
import "@fontsource/roboto/400.css"
import "@fontsource/roboto/700.css"

import "./src/styles/bootstrap.min.css"
import "./src/styles/pe-icon-7-stroke.css"
import "./src/styles/fontello.css"
import "./src/styles/nprogress.css"
import "./src/styles/magnific-popup.css"
import "./src/styles/uniform.default.css"
import "./src/styles/animations.css"
import "./src/styles/align.css"
import "./src/styles/main.css"
import "./src/styles/768.css"

import $ from 'jquery'
import NProgress from 'nprogress'
import 'magnific-popup'

export const onClientEntry = () => {
    NProgress.start();
}

export const onInitialClientRender = () => {
    NProgress.done();
}

export const onPreRouteUpdate = () => {
    NProgress.start();
    $('html').removeClass('is-menu-toggled-on');
}

export const onRouteUpdate = ({ location, prevLocation }) => {
    NProgress.done();

    if(location.pathname.startsWith('/resume')){
        // ENABLE BARS EFFECT
        $('.bar').each(function() {
            const bar = $(this);
            const percent = bar.attr('data-percent');
            bar.find('.progress').css('width', percent + '%' ).html(`<span>${percent}</span>`);
        });
    }

    if(location.pathname.startsWith('/work')){
        $('.p-overlay').css('display', 'block');
    } else {
        $('.p-overlay').css('display', 'none');
        // SET CURRENT MENU ITEM
        if(location.pathname.length > 1){
            //const pathClass = location.pathname.replaceAll('/', '');
            $(`.nav-menu a[href="${location.pathname}"]`).parent().addClass('current_page_item').siblings().removeClass('current_page_item');
        }
    }

    // HEADER MOBILE MENU
    $('.menu-toggle').on("click", function(e) {
        e.preventDefault();        
        $('html').toggleClass('is-menu-toggled-on');
        return false;
    });	
}
